<template>
  <div>
    <div class="p-3" v-if="result && exam">
      <Breadcrumb>
        <template slot="restPage">
          <el-breadcrumb-item>
            <router-link :to="{ name: 'SATResults', query: { practiceType } }">
              {{ $t("sat.SAT_Results") }}
            </router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item v-if="exam">
            <router-link
              :to="{
                name: 'SATResult',
                params: {
                  id: user_exam_id
                },
                query: { practiceType }
              }"
            >
              {{ getCompanyExamTitle(exam.title) }}
            </router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item v-if="result && exam">
            <kbd>{{ questionOrder }}</kbd>
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            Explanation
          </el-breadcrumb-item>
        </template>
      </Breadcrumb>
      <Heading class="mb-3" content="Explanation"></Heading>
      <div class="question-title" v-if="result && exam">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item
            :name="practiceIndex + 1"
            v-for="(practice, practiceIndex) in practices"
            :key="practiceIndex"
          >
            <template slot="title">
              <h6 class="m-0" v-if="practiceType === 'composePractices'">
                <!-- SAT Module {{ practiceIndex + 1 }} -->
                {{ getCompanyExamTitle(practice.exam.title) }}
                <span v-if="practice.subject && practice.subject.name">
                  : {{ titleCase(practice.subject.name) }}
                </span>
              </h6>
              <h6
                class="m-0"
                v-if="
                  practiceType === 'singlePractice' ||
                    practiceType === 'practice'
                "
              >
                {{ getCompanyExamTitle(practice.exam.title) }}
              </h6>
            </template>
            <div class="p-3">
              <div class="showTags">
                <div class="showTags-item">
                  <span class="view_question" />
                  <span style="line-height:1.8rem">Correct</span>
                </div>
                <div class="showTags-item">
                  <span class="view_question wrong" />
                  <span style="line-height:1.8rem">Wrong</span>
                </div>
                <div class="showTags-item">
                  <span class="view_question empty" />
                  <span style="line-height:1.8rem">Unanswered</span>
                </div>
              </div>
              <div>
                <router-link
                  :to="{
                    name: 'QuestionExplanation',
                    params: {
                      questionId: title.question_id
                    },
                    query: {
                      ...$route.query,
                      order: index + 1,
                      passageIndex: practiceIndex + 1,
                      user_exam_answer_id: title.userAnswer
                        ? title.userAnswer.id
                        : null
                    }
                  }"
                  v-for="(title, index) in practice.exam.exam_questions"
                  @click="viewBrowse(index)"
                  :key="index"
                  :class="{
                    view_question: true,
                    wrong: title.userAnswer
                      ? title.userAnswer.is_correct == 0
                      : false,
                    empty:
                      title.userAnswer === null ||
                      title.userAnswer.answers[0] === null
                  }"
                >
                  <span
                    v-if="title.question_id == questionId"
                    class="currentIcon questionsOrderActive"
                  >
                    <i class="fa-solid fa-location-dot" />
                  </span>
                  {{ index + 1 }}
                </router-link>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <template v-if="isAdmin">
      <div class="text-right ml-2 mr-2">
        <router-link
          :to="{
            name: 'SatEditQuestion',
            query: {
              questionId: question.id
            }
          }"
        >
          <el-button type="primary" size="medium">
            <i class="fa fa-edit"></i>
            Edit
          </el-button>
        </router-link>
      </div>
      <!-- <QuestionInfo :question="question"></QuestionInfo> -->
    </template>
    <div class="p-4">
      <el-card>
        <div class="test-paper" v-if="isPhone">
          <MultipleChoiceWithPassage_M
            v-if="
              question &&
                question.type === 'default' &&
                question.sat_passage_id > 0 &&
                question.sat_passage.content
            "
            mode="explanation"
            :passageIntro="
              question.sat_passage.introduction
                ? question.sat_passage.introduction.intro
                : nullF
            "
            :passageContent="getPassage"
            :questionOrder="questionOrder"
            :content="question.content"
            :options="question.sat_options"
            :answer="answer ? answer.answers[0] : '/'"
            :correctAnswer="question.sat_answers[0].answers[0]"
            :isCorrect="answer ? answer.is_correct == 1 : false"
            :explanation="question.explanation"
            :showMark="false"
            :showLine="question.sat_sat_subject.sat_subject.name === 'reading'"
            :activities="activities"
          ></MultipleChoiceWithPassage_M>
          <MultipleChoice_M
            v-else-if="
              question &&
                question.type === 'default' &&
                (question.sat_passage === null ||
                  question.sat_passage.content === null)
            "
            mode="explanation"
            :questionOrder="questionOrder"
            :content="question.content"
            :options="question.sat_options"
            :answer="answer ? answer.answers[0] : '/'"
            :correctAnswer="question.sat_answers[0].answers[0]"
            :isCorrect="answer ? answer.is_correct == 1 : false"
            :explanation="question.explanation"
            :showMark="false"
            :activities="activities"
          ></MultipleChoice_M>
          <Math_M
            v-else-if="question.type === 'math'"
            mode="explanation"
            :questionOrder="questionOrder"
            :content="question.content"
            :options="question.sat_options"
            :answer="answer ? answer.answers[0] : '/'"
            :correctAnswer="question.sat_answers[0].answers[0]"
            :isCorrect="answer ? answer.is_correct == 1 : false"
            :explanation="question.explanation"
            :showMark="false"
            :activities="activities"
          ></Math_M>
          <MultipleChoice_M
            v-else
            mode="explanation"
            :questionOrder="questionOrder"
            :content="question.content"
            :options="question.sat_options"
            :answer="answer ? answer.answers[0] : '/'"
            :correctAnswer="question.sat_answers[0].answers[0]"
            :isCorrect="answer ? answer.is_correct == 1 : false"
            :explanation="question.explanation"
            :showMark="false"
            :activities="activities"
          ></MultipleChoice_M>
        </div>
        <div class="test-paper" v-else>
          <MultipleChoiceWithPassage
            v-if="
              question &&
                question.type === 'default' &&
                question.sat_passage_id > 0 &&
                question.sat_passage.content
            "
            mode="explanation"
            :passageIntro="
              question.sat_passage.introduction
                ? question.sat_passage.introduction.intro
                : null
            "
            :passageContent="getPassage"
            :questionOrder="questionOrder"
            :content="question.content"
            :options="question.sat_options"
            :answer="answer ? answer.answers[0] : '/'"
            :correctAnswer="question.sat_answers[0].answers[0]"
            :isCorrect="answer ? answer.is_correct == 1 : false"
            :explanation="question.explanation"
            :isFree="isFree"
            :company="CompanyName"
            :email="CompanyEmail"
            :showMark="false"
            :showLine="question.sat_sat_subject.sat_subject.name === 'reading'"
            :activities="
              answer && answer.other_status ? answer.other_status.activities : null
            "
          ></MultipleChoiceWithPassage>
          <MultipleChoice
            v-else-if="
              question &&
                question.type === 'default' &&
                (question.sat_passage === null ||
                  question.sat_passage.content === null)
            "
            mode="explanation"
            :questionOrder="questionOrder"
            :content="question.content"
            :options="question.sat_options"
            :answer="answer ? answer.answers[0] : '/'"
            :correctAnswer="question.sat_answers[0].answers[0]"
            :isCorrect="answer ? answer.is_correct == 1 : false"
            :explanation="question.explanation"
            :isFree="isFree"
            :company="CompanyName"
            :email="CompanyEmail"
            :showMark="false"
            :activities="
              answer && answer.other_status ? answer.other_status.activities : null
            "
          ></MultipleChoice>
          <Math
            v-else-if="question && question.type === 'math'"
            mode="explanation"
            :questionOrder="questionOrder"
            :content="question.content"
            :options="question.sat_options"
            :answer="answer !== null ? answer.answers[0] : '/'"
            :correctAnswer="question.sat_answers[0].answers[0]"
            :isCorrect="answer ? answer.is_correct == 1 : false"
            :explanation="question.explanation"
            :isFree="isFree"
            :showMark="false"
            :company="CompanyName"
            :email="CompanyEmail"
            :activities="
              answer && answer.other_status ? answer.other_status.activities : null
            "
          ></Math>
        </div>
      </el-card>
    </div>
    <div v-if="isAdmin">
      <div class="pl-4 pr-4">
        <QuestionInfo :question="question"></QuestionInfo>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import $ from "jquery";
import Breadcrumb from "@/components/Breadcrumb";
import SAT from "@/apis/sat";
import role from "@/mixins/role.js";
import Common from "@/mixins/common.js";
import QuestionInfo from "@/views/satTestQuestionExplanation/QuestionInfo.vue";

export default {
  metaInfo() {
    return {
      title: "Explanation - " + this.CompanyName
    };
  },

  components: {
    Breadcrumb,
    QuestionInfo
  },

  mixins: [Common, role],

  props: [],
  data() {
    return {
      exam: null,
      practices: null,
      result: null,
      answer: null,
      question: null,
      activeName: null,
      annotation: []
    };
  },
  computed: {
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    isFree() {
      return (
        (this.result.other_status.from == "trial" || this.result.other_status.from == "company_expend") &&
        this.result.other_status.trial_status === 0
      );
    },
    questionId() {
      return this.$route.params.questionId;
    },
    user_exam_id() {
      return this.$route.query.user_exam_id;
    },
    questionOrder() {
      return this.$route.query.order;
    },
    practiceType() {
      return this.$route.query.practiceType;
    },
    user_exam_answer_id() {
      return this.$route.query.user_exam_answer_id;
    },
    getPassage() {
      let passage = "";
      if (
        this.answer &&
        this.answer.other_status &&
        this.answer.other_status.annotation &&
        this.answer.other_status.annotation.passage
      ) {
        passage = this.answer.other_status.annotation.passage;
      } else {
        if (this.question.sat_passage) {
          let p1 = this.question.sat_passage.content;
          let p2 = this.question.sat_passage.introduction?.below_content;
          if (p2) {
            passage = `<h5 style="padding-left:40px">Passage I</h5>${p1} <br /><h5 style="padding-left:40px">Passage II</h5>${p2}`;
          } else {
            passage = p1;
          }
        }
      }
      // passage = this.getLine(passage);
      return passage;
    }
  },
  watch: {},

  async mounted() {
    if (this.user_exam_id) {
      if (this.practiceType === "composePractices") {
        const res = await SAT.getComposePracticeResult(this.user_exam_id);
        this.exam = res.compose_practice.exam;
        this.practices = res.compose_practice.practices;
        this.result = res.result;
        this.getUserAnswers();
      } else if (
        this.practiceType === "singlePractice" ||
        this.practiceType === "practice"
      ) {
        const res = await SAT.getPracticeResult(this.user_exam_id);
        this.exam = res.practice.exam;
        this.practices = [res.practice];
        this.result = res.result;
        this.getUserAnswers();
      }
    }
    const res = await SAT.getExplanation(this.questionId, {
      user_exam_answer_id: this.user_exam_answer_id
    });
    if (this.$route.query.passageIndex) {
      this.activeName = parseInt(this.$route.query.passageIndex);
    }
    this.question = res.sat_question;
    if (!this.question.sat_sat_subject) {
      this.question.sat_sat_subject = {
        sat_subject: {
          id: 5,
          name: "english"
        }
      };
    }
    this.answer = res.user_exam_answer;

    console.log(this.answer.other_status.annotation);
    if (
      this.answer &&
      this.answer.other_status &&
      this.answer.other_status.annotation
    ) {
      this.annotation = this.answer.other_status.annotation.annotation;
      if (this.question.sat_passage) {
        this.question.sat_passage.content = this.answer.other_status.annotation
          .passage
          ? this.answer.other_status.annotation.passage
          : this.question.sat_passage.content;
      }
      this.question.content = this.answer.other_status.annotation.question
        ? this.answer.other_status.annotation.question
        : this.question.content;

      if (this.question.sat_options) {
        this.question.sat_options.forEach((option, index) => {
          option.title = this.answer.other_status.annotation.options[
            index
          ].title;
        });
      }
    }
    this.setHighlight();
  },

  methods: {
    // getLine(passage) {
    //   let new_passage = passage.replace(/[\n]/g, "<br>");
    //   new_passage = new_passage.replace(
    //     /<br>/g,
    //     "</p><p style='margin-bottom:0;height:34px;'>"
    //   );
    //   new_passage =
    //     "<p style='margin-bottom:0;height:34px;'>" + new_passage + "</p>";
    //   return new_passage;
    // },
    setHighlight() {
      this.$nextTick(() => {
        let vm = this;
        $("span[data-id]").each(function() {
          if ($(this).data("id") !== "") {
            $(this).addClass("highlight");
          }
        });
        $("span[data-id]").each(function() {
          if ($(this).data("id")) {
            $(this).addClass("highlight");
          }
        });
        $("span[data-id]").mouseover(function() {
          let annotationArr = [];
          if ($(this).data("id")) {
            annotationArr = $(this).data("id");
            let item = annotationArr[annotationArr.length - 1];
            $(".tooltipText").remove();
            $(this).append(
              `<span class="tooltipText">${vm.annotation[item].annotation}</span>`
            );
            $("span[data-id]").each(function() {
              if (
                $(this).data("id") &&
                $(this)
                  .data("id")
                  .indexOf(item) > -1
              ) {
                $(this).addClass("active");
              }
            });
          }
        });
        $("span[data-id]").mouseout(function() {
          $("span[data-id]").removeClass("active");
          $(".tooltipText").remove();
        });
      });
    },
    getUserAnswers() {
      let count = 0;
      this.practices.forEach(practice => {
        let arr = [];
        let correctCount = 0;

        practice.exam.exam_questions.forEach(question => {
          count++;
          let answer = null;
          this.result.user_exam_answers.forEach(userAnswer => {
            if (question.id === userAnswer.exam_question.id) {
              answer = userAnswer;
              if (userAnswer.is_correct === 1) {
                correctCount++;
              }
            }
          });
          question["userAnswer"] = answer;
          arr.push(answer);
        });
        practice["correctCount"] = correctCount;
      });
    }
  }
};
</script>

<style scoped>
.test-paper {
  margin-bottom: 4rem;
}
.question-title {
  margin: 0 -1rem;
}
::v-deep .el-collapse-item .el-collapse-item__header {
  padding: 0 1rem;
}
::v-deep .el-collapse-item__header:hover {
  background-color: var(--themeColor);
  color: white !important;
}
.view_question {
  position: relative;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  line-height: 1.8rem;
  text-align: center;
  font-weight: 700;
  font-size: 1rem;
  border-radius: 5px;
  margin: 1rem 5px 1rem;
  border: 0.1rem solid #d4edda;
  text-decoration: none;
  cursor: pointer;
  background-color: #d4edda;
  color: #212529 !important;
}
.wrong {
  border-color: #ffdadd;
  background-color: #ffdadd;
}
.view_question.empty {
  background-color: #ccc;
  border-color: #ccc;
}
.showTags {
  display: flex;
  /* justify-content: center; */
}
.showTags-item {
  margin: 0 10px 20px 0;
  display: flex;
}
.showTags-item .view_question {
  height: 2rem;
  width: 2rem;
  line-height: 1.8rem;
  margin: 0 5px;
}
.questionsOrderActive {
  position: absolute;
  display: inline-block;
  width: 1.8rem;
  left: 0;
  text-align: center;
  top: -1.7rem;
  color: var(--themeColor);
}
::v-deep .MathJax .mrow {
  font-size: 1.2rem !important;
}
</style>
